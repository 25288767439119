import React, { FC, useEffect, useState } from "react";

import MC from '../../common/assets/paymentSystems/master-card-icon.png';
import MIR from '../../common/assets/paymentSystems/mir-icon.png';
import UP from '../../common/assets/paymentSystems/up-icon.png';
import VISA from '../../common/assets/paymentSystems/visa-icon.png';
import './PaymentSystem.scss';

type CardType = 'mc' | 'visa' | 'up' | 'mir';

interface Props {
  type: CardType;
};

export const PaymentSystem: FC<Props> = ({ type }) => {
  const [image, setImage] = useState('');

  useEffect(() => {
    switch (type) {
      case 'mc':
        setImage(MC);
        break;

      case 'visa':
        setImage(VISA);
        break;
      case 'up':
        setImage(UP);
        break;
      case 'mir':
        setImage(MIR);
        break;
    }
  }, [type]);


  return (
    <div className="Payment-system__container">
      <img className="Payment-system__image" src={image} alt={type} />
    </div>
  );
};