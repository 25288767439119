import React, { FC } from "react";

export const SingleIcon: FC = () => (
  <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1623_4647)">
      <path d="M41.3751 17.9121C38.7758 16.4798 36.1355 14.945 34.2988 12.6221C32.3807 10.1963 31.5619 6.56797 33.3395 4.03853C34.8327 1.91379 37.8062 1.16671 40.339 1.78075C42.8719 2.39479 44.9923 4.14422 46.6392 6.15362C46.1003 3.68386 48.7201 1.29916 51.2524 1.51788C53.7847 1.7366 55.8166 3.9372 56.4927 6.37336C57.1689 8.80952 56.7289 11.4166 56.0034 13.8387C54.7818 17.8998 52.7853 21.6892 50.1229 25C49.7147 24.2647 49.2213 23.5793 48.6529 22.958C46.6536 20.7719 43.9744 19.3444 41.3751 17.9121Z" fill="#F6193D" />
      <path d="M50.358 25.1172C53.7595 21.3884 56.2508 16.9303 57.6385 12.0891C58.3517 9.57429 58.7694 6.71501 57.7006 4.23389C56.874 2.31489 55.1121 0.578628 52.928 0.417887C50.6231 0.248258 48.0644 2.37032 48.5582 4.79687C48.595 4.97809 48.355 5.16926 48.2128 4.99675C46.3679 2.75789 43.8319 0.695583 40.8098 0.493418C38.1181 0.313353 35.2905 1.65071 34.5065 4.36935C33.6898 7.20102 35.0988 10.1967 37.0721 12.1991C39.261 14.4203 42.1104 15.8059 44.7998 17.3186C47.446 18.8071 50.1374 20.5258 51.765 23.1569C52.5382 24.3738 53.0161 25.7529 53.1607 27.1852C53.2885 28.6635 52.9166 30.0613 52.1968 31.3504C51.4322 32.7196 50.4867 33.9679 49.7963 35.3817C49.1172 36.779 48.6007 38.2488 48.2568 39.7625C47.5621 42.7798 47.5575 45.9136 48.2434 48.9329C49.5121 54.4355 53.214 59.2786 58.1407 62.0647C58.736 62.4006 59.348 62.7065 59.9743 62.9812C60.217 63.0879 60.0069 63.442 59.7657 63.3359C57.156 62.1714 54.8099 60.4945 52.8685 58.4058C50.927 56.3171 49.4302 53.8599 48.468 51.1816C46.3836 45.2986 47.1827 38.5202 50.5171 33.2465C51.3898 31.8663 52.3978 30.5278 52.6852 28.8834C52.9485 27.3766 52.6242 25.8047 51.9843 24.4301C50.6138 21.4862 47.8975 19.5726 45.1537 17.9932C42.4106 16.4142 39.4803 15.0468 37.1556 12.8567C35.0862 10.9071 33.5595 8.12524 33.9178 5.21096C34.0658 3.87917 34.6893 2.64434 35.6753 1.73089C36.6692 0.861844 37.9023 0.308873 39.2154 0.143325C42.1449 -0.267302 44.8909 1.11458 46.9789 3.06797C47.5226 3.58074 48.0324 4.12797 48.505 4.70623L48.1597 4.9061C47.714 2.71585 49.4652 0.703645 51.5157 0.149695C53.7456 -0.452721 55.9561 0.84337 57.2411 2.60046C58.8461 4.79524 58.9774 7.60986 58.5082 10.1977C58.0215 12.8822 57.0139 15.5104 55.7902 17.9442C54.4257 20.6526 52.6965 23.1635 50.6502 25.4077C50.4713 25.6041 50.1798 25.3129 50.358 25.1172L50.358 25.1172Z" fill="#3F3D56" />
      <path d="M51.7981 99.0634L53.7481 99.6136L61.4102 75.3543L55.7879 74.3906L51.7981 99.0634Z" fill="#FFB8B8" />
      <path d="M74.6848 99.2002L76.6133 98.7894L71.6247 73.9297L66.2001 75.6867L74.6848 99.2002Z" fill="#FFB8B8" />
      <path d="M54.1492 103.775C53.6152 104.017 53.1679 102.515 52.7175 102.955C51.4577 104.186 48.4354 103.91 48.4354 103.91C48.3339 103.878 48.2401 103.825 48.1597 103.756C48.0792 103.686 48.0138 103.601 47.9674 103.506C47.921 103.411 47.8946 103.307 47.8897 103.201C47.8848 103.095 47.9016 102.989 47.939 102.89C47.9838 102.771 48.0569 102.665 48.1521 102.581C48.2473 102.496 48.3616 102.436 48.4854 102.405L49.8252 102.072L51.929 98.394L54.2217 98.1055L54.2796 98.1996C54.7228 98.9099 55.0086 99.7059 55.1178 100.535C55.2271 101.363 55.1573 102.206 54.9131 103.006C54.7224 103.613 54.4629 103.632 54.1492 103.775Z" fill="#2F2E41" />
      <path d="M77.4868 102.704C77.0063 103.039 76.2906 101.644 75.9287 102.159C74.9163 103.598 71.8942 103.876 71.8942 103.876C71.7885 103.862 71.6866 103.828 71.5947 103.774C71.5029 103.72 71.4229 103.648 71.3598 103.563C71.2966 103.478 71.2515 103.381 71.2272 103.277C71.203 103.174 71.2 103.067 71.2186 102.963C71.2408 102.838 71.2932 102.72 71.3712 102.62C71.4493 102.52 71.5507 102.44 71.6667 102.387L72.9228 101.816L74.315 97.8174L76.516 97.1172L76.5903 97.1992C77.1566 97.817 77.5839 98.5477 77.8436 99.3426C78.1033 100.138 78.1895 100.978 78.0965 101.809C78.0207 102.441 77.7691 102.507 77.4868 102.704Z" fill="#2F2E41" />
      <path d="M58.2954 56.875C57.2685 58.445 56.2731 60.0307 55.8328 61.8353C54.768 66.2525 54.0077 70.7368 53.5572 75.2569C57.1248 77.2921 60.2201 78.4603 63.2436 78.6616C63.3842 78.6709 63.5246 78.6775 63.6649 78.6827L64.7671 72.4959L65.6464 78.6145C66.0818 78.5685 66.5178 78.5029 66.956 78.4151C68.8514 78.0371 70.7812 77.2647 72.8433 76.0786C73.5826 73.155 73.893 70.1405 73.7649 67.1286C73.5954 63.1008 72.6794 59.6522 71.7923 56.883L58.2954 56.875Z" fill="#2F2E41" />
      <path d="M65.868 38.6333L70.7148 36.9949C68.9614 35.2883 68.2453 33.4145 68.97 31.3086L63.5415 32.2724C64.8296 34.7406 66.5161 36.2269 65.868 38.6333Z" fill="#FFB8B8" />
      <path d="M58.2967 56.874C62.7056 60.361 67.1943 60.7629 71.7936 56.882C69.9395 51.0943 73.7221 45.6517 73.1711 41.543C72.8274 38.9797 71.7551 35.8046 69.2618 35.0664L66.584 35.8799C65.6551 36.1617 64.8146 36.6759 64.1429 37.3733C63.4711 38.0706 62.9907 38.9278 62.7477 39.8626L62.4309 41.0883C60.8977 42.7099 60.3867 44.8409 60.7848 47.428C60.4487 48.162 60.6029 49.0879 60.7572 50.0151C60.9032 50.897 61.0492 51.7804 60.7737 52.4994C60.1809 54.0452 59.2259 55.4532 58.2967 56.874" fill="#3F3D56" />
      <path d="M95.9426 100.445H99.2208C100.936 100.445 102.467 100.566 103.479 101.212C103.954 100.351 104.653 99.6338 105.503 99.1341C106.353 98.6343 107.323 98.3708 108.31 98.3711H111.588C112.312 98.3711 113.029 98.5129 113.698 98.7883C114.367 99.0638 114.975 99.4676 115.487 99.9766C115.999 100.486 116.405 101.09 116.682 101.755C116.959 102.42 117.102 103.133 117.102 103.853V103.853H90.4294V103.853C90.4294 100.825 92.8977 100.445 95.9426 100.445Z" fill="#E6E6E6" />
      <path d="M27.9961 104.002H13.9935C14.0643 101.404 16.0208 100.891 19.3557 100.891H22.6339C25.9688 100.891 27.9254 101.404 27.9961 104.002Z" fill="#E6E6E6" />
      <path d="M64.8996 34.4904C62.4904 34.4904 60.5374 32.5487 60.5374 30.1534C60.5374 27.7581 62.4904 25.8164 64.8996 25.8164C67.3087 25.8164 69.2617 27.7581 69.2617 30.1534C69.2617 32.5487 67.3087 34.4904 64.8996 34.4904Z" fill="#FFB8B8" />
      <path d="M49.9208 54.8468L52.9766 53.8524L52.7536 51.4144L49.3984 51.3403C49.1337 51.3344 48.871 51.3864 48.6287 51.4926C48.3865 51.5988 48.1706 51.7565 47.9964 51.9547C47.8222 52.1529 47.6939 52.3867 47.6205 52.6396C47.5472 52.8925 47.5305 53.1584 47.5718 53.4184C47.6127 53.6755 47.7092 53.9208 47.8547 54.1372C48.0002 54.3536 48.1912 54.536 48.4146 54.6718C48.6379 54.8077 48.8883 54.8937 49.1484 54.9239C49.4084 54.9541 49.672 54.9278 49.9208 54.8468Z" fill="#FFB8B8" />
      <path opacity="0.2" d="M55.8335 61.8345C57.0967 61.2456 59.9715 59.761 64.1246 56.7522C69.844 52.6079 68.3893 42.4883 68.3893 42.4883L60.7567 50.0153C60.9027 50.8973 61.0487 51.7807 60.7732 52.4997C59.5252 55.7537 56.6724 58.397 55.8335 61.8345Z" fill="black" />
      <path d="M52.3967 54.6322C56.5349 55.5438 59.9204 56.0903 62.6719 54.1503C67.0358 49.1043 69.5159 44.0386 68.5876 38.9372C68.5015 38.4777 68.2861 38.0519 67.9665 37.7091C67.6469 37.3662 67.2362 37.1205 66.7819 37.0002C66.1857 36.84 65.5509 36.9047 64.9997 37.1818C64.4486 37.4589 64.02 37.9288 63.7963 38.5012L60.4994 49.4871L51.959 51.1305L52.3967 54.6322Z" fill="#2F2E41" />
      <path d="M58.8808 65.5245C59.3664 65.5238 59.8373 65.3588 60.2161 65.0566C60.5949 64.7545 60.8589 64.3331 60.9648 63.862L61.2188 62.7254L57.0501 61.8047L56.7963 62.9411C56.6735 63.4907 56.7753 64.0663 57.0794 64.5413C57.3834 65.0163 57.8647 65.3517 58.4175 65.4739C58.5696 65.5075 58.725 65.5245 58.8808 65.5245ZM60.8901 62.9335L60.6959 63.8026C60.643 64.0396 60.5436 64.2639 60.4034 64.4627C60.2632 64.6616 60.0851 64.831 59.879 64.9613C59.673 65.0917 59.4432 65.1804 59.2027 65.2225C58.9622 65.2645 58.7157 65.2591 58.4773 65.2064C58.2389 65.1537 58.0133 65.0549 57.8133 64.9155C57.6133 64.7762 57.4429 64.599 57.3118 64.3942C57.1807 64.1894 57.0914 63.9608 57.0492 63.7217C57.0069 63.4826 57.0124 63.2375 57.0653 63.0005L57.2594 62.1316L60.8901 62.9335Z" fill="#3F3D56" />
      <path d="M60.5522 28.3393C60.5522 28.3393 59.852 22.9208 65.5657 24.6977C65.5657 24.6977 69.6465 23.9532 70.9848 29.0282L72.3398 34.3481L71.6794 33.9908L71.3716 34.6599L70.3014 34.9293L69.8435 34.0256L69.622 35.1231L60.8569 35.9918C60.8569 35.9918 64.7826 33.6836 64.8448 29.5466L64.0971 30.3323L60.5522 28.3393Z" fill="#2F2E41" />
      <path d="M112.477 100.962C112.477 100.962 109.794 71.9246 112.477 67.332C112.477 67.332 101.152 85.258 107.559 101.851L112.477 100.962Z" fill="#E6E6E6" />
      <path d="M110.285 100.815C110.285 100.815 101.682 85.5961 102.021 82.4336C102.021 82.4336 100.229 94.9857 107.813 102.497L110.285 100.815Z" fill="#E6E6E6" />
      <path d="M23.8164 102.154C23.8164 102.154 21.9196 81.619 23.8164 78.3711C23.8164 78.3711 15.8076 91.0485 20.3389 102.783L23.8164 102.154Z" fill="#E6E6E6" />
      <path d="M22.2656 102.046C22.2656 102.046 16.1811 91.2834 16.4209 89.0469C16.4209 89.0469 15.154 97.9238 20.5173 103.236L22.2656 102.046Z" fill="#E6E6E6" />
    </g>
    <defs>
      <clipPath id="clip0_1623_4647">
        <rect width="104" height="104" rx="15" transform="matrix(-1 0 0 1 104 0)" fill="white" />
      </clipPath>
    </defs>
  </svg>
);