import { useStore } from "effector-react";
import React, { FC, Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CoupleIcon, HeartIcon, rateStore } from "../../common";
import { Button } from "../../components";

import './Success.scss';

export const SuccessPage: FC = () => {
  const navigator = useNavigate();
  const { id, price, name, offers_count, body, category } = useStore(rateStore);

  useEffect(() => {
    window.scrollTo({ top: window.innerHeight });
    if (id) {
      (window as any).dataLayer.push({
        "ecommerce": {
          "currencyCode": "RUB",
          "purchase": {
            "products": [
              {
                id,
                name,
                price,
                category,
                "quantity": offers_count,
                "variant": body.split(' ')[0]
              }
            ]
          }
        }
      });
    }
  }, [id, price, name, offers_count, body, category]);

  const handleButtonClick: ((event: React.MouseEvent) => void) = e => {
    e.preventDefault();

    navigator('/rates');
  };

  return (
    <Fragment>
      <h1 className="Success-page__title Right-side__title">Успешная оплата <HeartIcon /> </h1>

      <p className="Right-side__info text text_semi-gray">
        Оплата прошла успешно! Средтсва будут зачислены на ваш счет в течении 30 минут. Надеемся, что наше приложение поможет вам изменить жизнь к лучшему.
      </p>

      <Button className="Success-page__pay-btn" onClick={handleButtonClick} text="Оплатить ещё раз" onlyBorder />

      <div className="Success-page__icon">
        <CoupleIcon />
      </div>

    </Fragment>
  )
}