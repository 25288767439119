import React, { FC } from 'react';
import {
  Routes,
  Route
} from 'react-router-dom';
import { AppStoreIcon, SocialIcon, } from '../../common';
import { CheckoutPage, FailurePage, NoUserPage, PhonePage, RatesPage, SuccessPage } from '../../pages';
import { Footer } from '../';

import './RightSide.scss';

export const RightSide: FC = () => {
  return (
    <div className="Right-side__container" style={{ 'minHeight': window.innerHeight }}>
      <div className="Right-side__container-sub">
        <Routes>
          <Route path="/no-user" element={<NoUserPage />} />
          <Route path="/fail" element={<FailurePage />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/rates" element={<RatesPage />} />
          <Route path="/" element={<PhonePage />} />
          <Route path="*" element={<PhonePage />} />
        </Routes>
      </div>

      <Footer />

      <div className="Right-side__footer">
        <div className="footer__desktop-info">
          <a className="footer__email-text" href="mailto:info@drsvat.ru">info@drsvat.ru</a>
          <div className="footer__social">
            <SocialIcon name="facebook" />
            <SocialIcon name="vk" />
            <SocialIcon name="ok" />
            <SocialIcon name='instagram' />
          </div>
        </div>

        <div className="Right-side__footer-right">
          <a href='https://play.google.com/store/apps/details?id=com.drsvat.drSvatApp&hl=en_US&gl=US' className="Right-side__google-play" target="_blank" rel="noreferrer"></a>
          <a href='https://apps.apple.com/ru/app/dr-svat-%D1%81%D0%B5%D1%80%D1%8C%D0%B5%D0%B7%D0%BD%D1%8B%D0%B5-%D0%B7%D0%BD%D0%B0%D0%BA%D0%BE%D0%BC%D1%81%D1%82%D0%B2%D0%B0/id1545816827' target="_blank" rel="noreferrer">
            <AppStoreIcon />
          </a>
        </div>
      </div>
    </div>
  );
};