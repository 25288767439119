import React, { FC } from "react";
import { GrayTieIcon, PairIcon } from "../../common";

import './TieLine.scss';

export const TieLine: FC = () => (
  <div className="tie-line__container">
    <PairIcon />
    <div className="footer__head">
      <div className="footer__head-line" />
      <GrayTieIcon />
      <div className="footer__head-line" />
    </div>
  </div>
)