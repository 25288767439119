import React, { FC } from "react";
import { EllipseIcon, GrayDotIcon } from "../../common";
import cn from 'classnames';

import './ProgressBar.scss';

interface Props {
  step?: 'one' | 'two' | 'three';
  className?: string;
}

export const ProgressBar: FC<Props> = ({ step = 'one', className }) => {
  const containerClasses = cn('Progress-bar__container', className, { 'Progress-bar__container_two': step === 'two', 'Progress-bar__container_three': step === 'three' });

  return (
    <div className={ containerClasses }>
      { step === 'one' ? <EllipseIcon /> : <GrayDotIcon isGray={false} />}
      <div className="Progress-bar__line"></div>
      { step === 'one' && <GrayDotIcon />}
      { step === 'two' && <EllipseIcon />}
      { step === 'three' && <GrayDotIcon isGray={false} />}
      <div className="Progress-bar__line"></div>
      { step === 'three' ? <EllipseIcon /> : <GrayDotIcon />}
    </div>
  );
};