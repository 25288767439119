import React, { FC } from "react";

interface Props {
  isGray?: boolean;
}

export const GrayDotIcon: FC<Props> = ({ isGray = true }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="8" fill={isGray ? '#8995A4' : '#F6193D'} stroke="#F2F4F6" strokeWidth="8" />
  </svg>
);
