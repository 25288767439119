import React, { FC, Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AloneIcon } from "../../common";
import { Button } from "../../components";

import './Failure.scss';

export const FailurePage: FC = () => {
  const navigator = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: window.innerHeight });
  }, []);

  const handleButtonClick: ((event: React.MouseEvent) => void) = e => {
    e.preventDefault();

    navigator('/rates');
  };

  return (
    <Fragment>
      <h1 className="Failure-page__title Right-side__title">Что-то пошло не так <span className="text_main-color ">:(</span></h1>

      <p className="Right-side__info text text_semi-gray">
        Не удалось совершить оплату! Проверьте баланс своего счета и правильность введенных данных. Если у вас возникли проблемы - напишите нашей службе поддержки
      </p>

      <div className="Failure-page__controls-container">
        <Button className="Failure-page__try-btn Success-page__pay-btn" onClick={handleButtonClick} text="Попробовать снова" />
        <Button className="Success-page__pay-btn" onClick={handleButtonClick} text="Напишите нам" onlyBorder />
      </div>

      <div className="Failure-page__icon">
        <AloneIcon />
      </div>

    </Fragment>
  )
}