import React, { FC } from "react";

export const TieIcon: FC = () => (
  <svg width="139" height="27" viewBox="0 0 139 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.7289 6.74003L17.037 10.13L11.3451 6.74003L0 0V13.5199V27L11.3451 20.26L17.037 16.87L22.7289 20.26L34.0739 27V13.5199V0L22.7289 6.74003Z" fill="#F6193D" />
    <path d="M50.2996 4.35784H57.8465C59.688 4.35784 61.324 4.733 62.7545 5.48331C64.1849 6.21658 65.2947 7.26531 66.084 8.62952C66.8732 9.97668 67.2678 11.537 67.2678 13.3105C67.2678 15.0839 66.8732 16.6528 66.084 18.017C65.2947 19.3642 64.1849 20.4129 62.7545 21.1632C61.324 21.8965 59.688 22.2631 57.8465 22.2631H50.2996V4.35784ZM57.6985 19.4494C58.9646 19.4494 60.0744 19.2022 61.028 18.7076C61.9981 18.1961 62.738 17.4798 63.2477 16.559C63.7739 15.6211 64.0369 14.5383 64.0369 13.3105C64.0369 12.0827 63.7739 11.0084 63.2477 10.0875C62.738 9.14963 61.9981 8.43342 61.028 7.93889C60.0744 7.42731 58.9646 7.17152 57.6985 7.17152H53.5058V19.4494H57.6985Z" fill="black" />
    <path d="M73.3145 10.5991C74.2023 9.16668 75.7643 8.45047 78.0005 8.45047V11.4944C77.7374 11.4432 77.499 11.4176 77.2852 11.4176C76.085 11.4176 75.1478 11.7843 74.4736 12.5175C73.7995 13.2337 73.4625 14.2739 73.4625 15.6382V22.2631H70.3796V8.60395H73.3145V10.5991Z" fill="black" />
    <path d="M81.4818 22.4422C80.9228 22.4422 80.4459 22.2461 80.0513 21.8538C79.6567 21.4446 79.4594 20.933 79.4594 20.3191C79.4594 19.6882 79.6485 19.1766 80.0267 18.7844C80.4213 18.3922 80.9063 18.1961 81.4818 18.1961C82.0573 18.1961 82.5341 18.3922 82.9122 18.7844C83.3069 19.1766 83.5042 19.6882 83.5042 20.3191C83.5042 20.933 83.3069 21.4446 82.9122 21.8538C82.5176 22.2461 82.0408 22.4422 81.4818 22.4422Z" fill="black" />
    <path d="M92.4052 22.5189C91.0734 22.5189 89.7827 22.3228 88.5331 21.9306C87.3 21.5384 86.3217 21.0183 85.5982 20.3703L86.708 17.7868C87.4151 18.3666 88.2783 18.8441 89.2977 19.2192C90.3335 19.5773 91.3694 19.7564 92.4052 19.7564C93.6877 19.7564 94.6413 19.5432 95.2661 19.1169C95.9074 18.6906 96.228 18.1278 96.228 17.4287C96.228 16.9171 96.0471 16.4993 95.6854 16.1753C95.3401 15.8343 94.8962 15.5699 94.3536 15.3824C93.811 15.1948 93.0711 14.9816 92.1339 14.7429C90.8186 14.4189 89.7498 14.0949 88.9277 13.7709C88.1221 13.4469 87.4233 12.9438 86.8314 12.2617C86.2559 11.5626 85.9682 10.6247 85.9682 9.44805C85.9682 8.459 86.223 7.56374 86.7327 6.76226C87.2589 5.94374 88.0398 5.29573 89.0757 4.81826C90.128 4.34079 91.4105 4.10205 92.9231 4.10205C93.9754 4.10205 95.0113 4.23847 96.0307 4.51131C97.0501 4.78416 97.9297 5.17637 98.6696 5.68795L97.6584 8.27142C96.9021 7.811 96.1129 7.46142 95.2908 7.22268C94.4687 6.98395 93.6712 6.86458 92.8985 6.86458C91.6324 6.86458 90.687 7.08626 90.0622 7.52963C89.4539 7.973 89.1497 8.56131 89.1497 9.29458C89.1497 9.80616 89.3223 10.2239 89.6676 10.5479C90.0293 10.8719 90.4815 11.1277 91.0241 11.3153C91.5667 11.5029 92.3066 11.7161 93.2437 11.9548C94.5262 12.2617 95.5785 12.5857 96.4006 12.9268C97.2227 13.2508 97.9215 13.7538 98.497 14.4359C99.0889 15.1181 99.3848 16.0389 99.3848 17.1985C99.3848 18.1875 99.1218 19.0828 98.5956 19.8843C98.0859 20.6857 97.3049 21.3252 96.2526 21.8027C95.2003 22.2802 93.9179 22.5189 92.4052 22.5189Z" fill="black" />
    <path d="M114.359 8.60395L108.711 22.2631H105.529L99.8816 8.60395H103.088L107.182 18.7332L111.399 8.60395H114.359Z" fill="black" />
    <path d="M120.907 8.45047C122.848 8.45047 124.327 8.93647 125.347 9.90847C126.383 10.8634 126.901 12.3129 126.901 14.2569V22.2631H123.99V20.6005C123.612 21.1973 123.07 21.6577 122.363 21.9817C121.672 22.2887 120.833 22.4422 119.847 22.4422C118.86 22.4422 117.997 22.2716 117.257 21.9306C116.517 21.5725 115.942 21.0865 115.531 20.4726C115.136 19.8416 114.939 19.1339 114.939 18.3495C114.939 17.1217 115.375 16.1412 116.246 15.4079C117.134 14.6576 118.523 14.2825 120.414 14.2825H123.818V14.0778C123.818 13.1229 123.538 12.3896 122.979 11.8781C122.437 11.3665 121.623 11.1107 120.537 11.1107C119.798 11.1107 119.066 11.2301 118.342 11.4688C117.635 11.7075 117.035 12.0401 116.542 12.4664L115.334 10.1387C116.024 9.593 116.854 9.17521 117.825 8.88531C118.795 8.59542 119.822 8.45047 120.907 8.45047ZM120.488 20.1145C121.261 20.1145 121.943 19.9354 122.535 19.5773C123.144 19.2022 123.571 18.6735 123.818 17.9914V16.4055H120.636C118.86 16.4055 117.972 17.0109 117.972 18.2216C117.972 18.8014 118.194 19.2618 118.638 19.6029C119.082 19.9439 119.699 20.1145 120.488 20.1145Z" fill="black" />
    <path d="M138.774 21.5213C138.412 21.8283 137.968 22.0585 137.442 22.2119C136.932 22.3654 136.39 22.4422 135.814 22.4422C134.367 22.4422 133.249 22.0499 132.46 21.2655C131.671 20.4811 131.276 19.3386 131.276 17.8379V11.2642H129.106V8.70626H131.276V5.58563H134.359V8.70626H137.886V11.2642H134.359V17.7612C134.359 18.4263 134.515 18.9378 134.828 19.2959C135.14 19.637 135.592 19.8075 136.184 19.8075C136.875 19.8075 137.45 19.6199 137.911 19.2448L138.774 21.5213Z" fill="black" />
  </svg>
);