import React, { FC, Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "effector-react";
import axios from "axios";

import { phoneStore, profileStore, rateStore } from "../../common";
import { BackButton, Button, ProgressBar } from "../../components";

import './Checkout.scss';

export const CheckoutPage: FC = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const navigator = useNavigate();

  const phone = useStore(phoneStore);
  const { id, price, name, price_full, offers_count, sale, body } = useStore(rateStore);
  const { age } = useStore(profileStore);

  useEffect(() => {
    if (!id) {
      navigator('/rates');
    } else if (phone === '') {
      navigator('/');
    }
  }, [id, navigator, phone]);

  const btnText = price ? `Оплатить ${price.split('.')[0]}` : '';

  const handleButtonClick: ((event: React.MouseEvent) => void) = async e => {
    e.preventDefault();
    setIsButtonDisabled(true);

    try {
      (window as any).dataLayer.push({
        "ecommerce": {
          "currencyCode": "RUB",
          "add": {
            "products": [
              {
                id,
                name,
                price,
                category: age,
                "quantity": offers_count,
                "variant": body.split(' ')[0]
              }
            ]
          }
        }
      });  
      const { data: { data: { payment_link } } } = await axios.get(`https://api.drsvat.com/api/v1/payments/generate-link?phone=+7 ${phone}&tariff_id=${id}`);
      window.location.replace(payment_link);
    } catch (error) {
      console.warn('error', error);
    }
  };

  return (
    <Fragment>
      <h1 className="Right-side__title">Оплатить тариф</h1>

      <ProgressBar step="three" />

      <BackButton path="/rates" />

      <h2 className="Right-side__sub-title Right-side__sub-title_nm">Оплата</h2>

      {name && (
        <Fragment>
          <div className="Checkout-page__rate">
            <div className="Checkout-page__item">
              <p className="Checkout-page__item-title">
                Тариф
              </p>

              <p className="Checkout-page__item-value">
                {name}
              </p>
            </div>
            {sale !== 0 && (
              <div className="Checkout-page__item">
                <p className="Checkout-page__item-title">
                  Цена
                </p>

                <p className="Checkout-page__item-value">
                  {price_full}
                </p>
              </div>
            )}
            <div className="Checkout-page__item">
              <p className="Checkout-page__item-title">
                Анкет в тарифе
              </p>

              <p className="Checkout-page__item-value">
                {offers_count}
              </p>
            </div>
            {sale !== 0 && (
              <div className="Checkout-page__item">
                <p className="Checkout-page__item-title">
                  Скидка
                </p>

                <p className="Checkout-page__item-value text_main-color">
                  {sale}%
                </p>
              </div>
            )}
            <div className="Checkout-page__item">
              <p className="Checkout-page__item-title">
                Свиданий в тарифе
              </p>

              <p className="Checkout-page__item-value">
                {body.split(' ')[0]}
              </p>
            </div>
            <div className="Checkout-page__item">
              <p className="Checkout-page__item-title">
                К оплате
              </p>

              <p className="Checkout-page__item-value">
                {price}
              </p>
            </div>
          </div>

          <Button className="Checkout-page__button" text={btnText} onClick={handleButtonClick} disabled={isButtonDisabled} />

          <p className="Checkout-page__info Right-side__info text text_semi-gray d-none d-block_sl">
            Оформляя покупку, вы <br />
            соглашаетесь с <a href="https://drsvat.com/terms" target="_blank" className="text_main-color" rel="noreferrer">Договором оферты</a>
          </p>
        </Fragment>
      )}

    </Fragment>
  );
};
