import React, { FC } from "react";

export const AppStoreIcon: FC = () => (
  <svg width="104" height="32" viewBox="0 0 104 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M95.7181 -0.000140141H8.28659C7.96789 -0.000140141 7.65301 -0.000140141 7.33516 0.00145986C7.06908 0.00305986 6.80513 0.00770786 6.5365 0.0116199C5.95291 0.0179413 5.37069 0.0652009 4.79491 0.152988C4.21993 0.242686 3.66297 0.411793 3.14286 0.654588C2.62338 0.899437 2.14871 1.21759 1.73609 1.59752C1.32129 1.97637 0.975532 2.41427 0.712097 2.89439C0.447934 3.37355 0.264763 3.88701 0.16891 4.41705C0.0721448 4.94641 0.0200748 5.48188 0.0131669 6.01865C0.00510162 6.26393 0.0042499 6.51002 0 6.75533V25.2467C0.0042499 25.4951 0.00510162 25.7358 0.0131669 25.9843C0.0200769 26.521 0.0721469 27.0564 0.16891 27.5858C0.264498 28.1161 0.44768 28.6299 0.712097 29.1092C0.975413 29.5878 1.32122 30.0239 1.73609 30.4007C2.14715 30.7823 2.62212 31.1007 3.14286 31.3436C3.66297 31.5871 4.21987 31.7572 4.79491 31.8483C5.37079 31.9354 5.95295 31.9826 6.5365 31.9897C6.80513 31.9951 7.06908 31.9983 7.33516 31.9983C7.653 31.9999 7.96791 31.9999 8.28659 31.9999H95.7181C96.0304 31.9999 96.3478 31.9999 96.6602 31.9983C96.925 31.9983 97.1966 31.9951 97.4614 31.9897C98.0439 31.983 98.6249 31.9357 99.1996 31.8483C99.7765 31.7565 100.335 31.5865 100.858 31.3436C101.378 31.1005 101.853 30.7821 102.264 30.4007C102.677 30.0224 103.024 29.5866 103.29 29.1092C103.553 28.6295 103.734 28.1158 103.829 27.5858C103.925 27.0564 103.979 26.5211 103.99 25.9843C103.993 25.7358 103.993 25.4951 103.993 25.2467C104 24.9561 104 24.6671 104 24.3717V7.62877C104 7.3358 104 7.04517 103.993 6.75533C103.993 6.51002 103.993 6.26393 103.99 6.01861C103.979 5.4818 103.925 4.94645 103.829 4.41701C103.734 3.88729 103.553 3.37386 103.29 2.89436C102.754 1.93202 101.903 1.14868 100.858 0.654516C100.335 0.412314 99.7764 0.243252 99.1996 0.152916C98.625 0.0647421 98.0439 0.0174661 97.4614 0.0115079C97.1966 0.00760386 96.925 0.00291586 96.6602 0.00135586C96.3478 -0.000244141 96.0304 -0.000244141 95.7181 -0.000244141V-0.000140141Z" fill="#A6A6A6" />
    <path d="M7.33936 31.2999C7.07457 31.2999 6.81617 31.2968 6.55345 31.2914C6.0092 31.2849 5.46623 31.2412 4.92898 31.1609C4.42802 31.0815 3.94273 30.9338 3.4891 30.7226C3.03964 30.5132 2.6297 30.2386 2.27497 29.9093C1.91511 29.5839 1.61551 29.2065 1.38805 28.7921C1.15807 28.375 0.998919 27.9279 0.916126 27.4663C0.826717 26.9704 0.778343 26.469 0.771421 25.9663C0.765911 25.7976 0.758698 25.2359 0.758698 25.2359V6.75542C0.758698 6.75542 0.76638 6.20229 0.771465 6.03979C0.778092 5.53794 0.826185 5.03727 0.915327 4.54213C0.998273 4.07935 1.15755 3.63095 1.38765 3.21245C1.61428 2.79831 1.91221 2.42064 2.26991 2.09409C2.6272 1.76445 3.03845 1.48843 3.48868 1.27612C3.94126 1.06563 4.42563 0.918937 4.92558 0.840967C5.46459 0.75982 6.00945 0.715948 6.55558 0.709719L7.33979 0.699951H96.6559L97.4495 0.710111C97.9907 0.716031 98.5306 0.759511 99.0646 0.840183C99.5696 0.919131 100.059 1.06684 100.517 1.27846C101.419 1.70634 102.153 2.38328 102.616 3.21441C102.842 3.63001 102.999 4.07476 103.081 4.53354C103.171 5.03274 103.222 5.53734 103.232 6.04331C103.234 6.26987 103.234 6.51323 103.234 6.75542C103.241 7.05542 103.241 7.34097 103.241 7.62885V24.3718C103.241 24.6624 103.241 24.946 103.234 25.2319C103.234 25.4921 103.234 25.7304 103.231 25.9757C103.221 26.4726 103.171 26.9682 103.082 27.4585C103.001 27.9234 102.843 28.374 102.613 28.7945C102.384 29.2044 102.086 29.5786 101.731 29.9031C101.375 30.2341 100.965 30.5103 100.514 30.7211C100.058 30.9339 99.5691 31.0821 99.0646 31.1609C98.5274 31.2417 97.9844 31.2853 97.4401 31.2914C97.1855 31.2968 96.919 31.2999 96.6602 31.2999L95.7181 31.3015L7.33936 31.2999Z" fill="black" />
    <path d="M21.5267 16.2404C21.536 15.5726 21.7287 14.9179 22.0868 14.3372C22.4449 13.7564 22.9568 13.2685 23.5748 12.9187C23.1822 12.4026 22.6642 11.9778 22.0621 11.6782C21.46 11.3785 20.7902 11.2123 20.1061 11.1926C18.6466 11.0516 17.2318 11.9964 16.4881 11.9964C15.73 11.9964 14.585 11.2066 13.3519 11.2299C12.5544 11.2536 11.7771 11.4671 11.0958 11.8496C10.4146 12.232 9.85255 12.7704 9.46453 13.4122C7.78369 16.091 9.03744 20.0278 10.6475 22.1931C11.4531 23.2534 12.3946 24.4377 13.6265 24.3957C14.832 24.3497 15.2822 23.6881 16.7374 23.6881C18.179 23.6881 18.6014 24.3957 19.8583 24.369C21.1518 24.3497 21.9668 23.304 22.7441 22.2337C23.3229 21.4782 23.7683 20.6432 24.0638 19.7597C23.3123 19.4671 22.671 18.9774 22.2198 18.3515C21.7686 17.7257 21.5276 16.9915 21.5267 16.2404Z" fill="white" />
    <path d="M19.1527 9.76857C19.858 8.9892 20.2055 7.98746 20.1214 6.97607C19.0438 7.08025 18.0485 7.5543 17.3337 8.30376C16.9841 8.66989 16.7165 9.09584 16.5459 9.55726C16.3754 10.0187 16.3053 10.5065 16.3397 10.9929C16.8786 10.998 17.4118 10.8905 17.8991 10.6784C18.3864 10.4663 18.815 10.1552 19.1527 9.76857Z" fill="white" />
    <path d="M36.7649 21.7114H32.6511L31.6632 24.3966H29.9208L33.8173 14.4622H35.6276L39.5242 24.3966H37.752L36.7649 21.7114ZM33.0772 20.4723H36.3381L34.7306 16.1145H34.6856L33.0772 20.4723Z" fill="white" />
    <path d="M47.9393 20.7757C47.9393 23.0264 46.6306 24.4725 44.6556 24.4725C44.1553 24.4966 43.6577 24.3905 43.2199 24.1664C42.782 23.9423 42.4216 23.6092 42.1799 23.2053H42.1425V26.7928H40.5273V17.1538H42.0907V18.3585H42.1204C42.3733 17.9565 42.7398 17.6251 43.181 17.3997C43.6222 17.1742 44.1215 17.063 44.6259 17.078C46.6229 17.078 47.9393 18.5311 47.9393 20.7757ZM46.2792 20.7757C46.2792 19.3093 45.4559 18.3452 44.1998 18.3452C42.9658 18.3452 42.1357 19.3296 42.1357 20.7757C42.1357 22.235 42.9658 23.2124 44.1998 23.2124C45.4559 23.2124 46.2792 22.2553 46.2792 20.7757Z" fill="white" />
    <path d="M56.5998 20.7757C56.5998 23.0264 55.2911 24.4725 53.3161 24.4725C52.8158 24.4966 52.3183 24.3905 51.8804 24.1664C51.4426 23.9423 51.0821 23.6092 50.8404 23.2053H50.803V26.7928H49.1879V17.1538H50.7512V18.3585H50.7809C51.0338 17.9565 51.4003 17.6251 51.8415 17.3997C52.2827 17.1742 52.782 17.063 53.2864 17.078C55.2835 17.078 56.5998 18.5311 56.5998 20.7757ZM54.9397 20.7757C54.9397 19.3093 54.1165 18.3452 52.8603 18.3452C51.6263 18.3452 50.7962 19.3296 50.7962 20.7757C50.7962 22.235 51.6263 23.2124 52.8603 23.2124C54.1165 23.2124 54.9397 22.2553 54.9397 20.7757H54.9397Z" fill="white" />
    <path d="M62.3236 21.6287C62.4433 22.6139 63.483 23.2607 64.9037 23.2607C66.2651 23.2607 67.2445 22.6138 67.2445 21.7255C67.2445 20.9544 66.6538 20.4927 65.2551 20.1763L63.8564 19.8662C61.8746 19.4255 60.9546 18.5724 60.9546 17.188C60.9546 15.474 62.5774 14.2966 64.8817 14.2966C67.1622 14.2966 68.7256 15.474 68.7782 17.188H67.1478C67.0502 16.1966 66.1598 15.5982 64.8587 15.5982C63.5576 15.5982 62.6673 16.2037 62.6673 17.0849C62.6673 17.7873 63.236 18.2005 64.627 18.5169L65.8161 18.7857C68.0304 19.2677 68.9505 20.0865 68.9505 21.5396C68.9505 23.3982 67.3421 24.5622 64.784 24.5622C62.3906 24.5622 60.7746 23.4255 60.6702 21.6286L62.3236 21.6287Z" fill="white" />
    <path d="M72.4362 15.4397V17.1538H73.9325V18.3311H72.4362V22.3241C72.4362 22.9444 72.7358 23.2335 73.3936 23.2335C73.5712 23.2306 73.7486 23.2191 73.9249 23.1991V24.3694C73.6291 24.4202 73.3286 24.4433 73.0278 24.4381C71.4347 24.4381 70.8134 23.8873 70.8134 22.4826V18.3311H69.6693V17.1538H70.8134V15.4397H72.4362Z" fill="white" />
    <path d="M74.7991 20.7756C74.7991 18.4967 76.2572 17.0647 78.5309 17.0647C80.8123 17.0647 82.2636 18.4967 82.2636 20.7756C82.2636 23.0608 80.82 24.4866 78.5309 24.4866C76.2427 24.4866 74.7991 23.0608 74.7991 20.7756ZM80.6179 20.7756C80.6179 19.2124 79.8397 18.2897 78.5309 18.2897C77.2222 18.2897 76.4448 19.2194 76.4448 20.7756C76.4448 22.3452 77.2222 23.2608 78.5309 23.2608C79.8397 23.2608 80.6179 22.3452 80.6179 20.7756H80.6179Z" fill="white" />
    <path d="M83.5953 17.1537H85.1358V18.3865H85.1732C85.2774 18.0015 85.5224 17.6612 85.8671 17.4229C86.2118 17.1846 86.6351 17.0628 87.0658 17.0779C87.2519 17.0773 87.4376 17.0959 87.6192 17.1334V18.524C87.3842 18.4579 87.1391 18.4276 86.8935 18.4342C86.6589 18.4254 86.4249 18.4635 86.2078 18.5458C85.9907 18.6281 85.7954 18.7527 85.6356 18.911C85.4757 19.0693 85.3549 19.2576 85.2815 19.4629C85.2081 19.6683 85.1839 19.8858 85.2105 20.1006V24.3967H83.5953L83.5953 17.1537Z" fill="white" />
    <path d="M95.066 22.2694C94.8487 23.5842 93.4577 24.4866 91.6779 24.4866C89.3889 24.4866 87.9681 23.0748 87.9681 20.81C87.9681 18.5381 89.3965 17.0647 91.61 17.0647C93.787 17.0647 95.156 18.4413 95.156 20.6374V21.1467H89.5985V21.2366C89.5728 21.5031 89.6091 21.7717 89.7049 22.0243C89.8006 22.277 89.9537 22.508 90.1539 22.7021C90.3541 22.8961 90.5968 23.0487 90.866 23.1498C91.1352 23.2508 91.4247 23.298 91.7152 23.2881C92.0969 23.321 92.4799 23.2397 92.8073 23.0561C93.1347 22.8726 93.389 22.5966 93.5324 22.2694L95.066 22.2694ZM89.6061 20.1077H93.54C93.5545 19.868 93.515 19.6281 93.424 19.4032C93.3331 19.1782 93.1925 18.973 93.0113 18.8004C92.8301 18.6277 92.6121 18.4915 92.3709 18.4001C92.1298 18.3087 91.8707 18.2642 91.61 18.2694C91.347 18.2679 91.0862 18.3145 90.8429 18.4062C90.5995 18.498 90.3782 18.6333 90.1919 18.8042C90.0056 18.9751 89.858 19.1783 89.7574 19.402C89.6569 19.6257 89.6054 19.8656 89.6061 20.1077V20.1077Z" fill="white" />
    <path d="M31.9293 9.00266H32.6227C32.7421 9.01635 32.8633 9.00712 32.9786 8.97556C33.0939 8.94401 33.2008 8.89081 33.2927 8.81932C33.3845 8.74783 33.4593 8.6596 33.5123 8.56019C33.5653 8.46078 33.5953 8.35235 33.6005 8.24173C33.6005 7.80149 33.2406 7.51712 32.6304 7.51712C32.5081 7.50139 32.3836 7.50837 32.2643 7.53766C32.145 7.56695 32.0333 7.61794 31.9357 7.68764C31.8382 7.75733 31.7568 7.8443 31.6963 7.94343C31.6359 8.04256 31.5977 8.15182 31.5839 8.26478H30.8226C30.8357 8.05974 30.8945 7.85937 30.9954 7.67617C31.0962 7.49296 31.237 7.33082 31.4089 7.19986C31.5808 7.0689 31.7801 6.97191 31.9945 6.91493C32.2089 6.85795 32.4337 6.84221 32.655 6.86868C33.676 6.86868 34.3949 7.39485 34.3949 8.15227C34.3973 8.40601 34.3051 8.65284 34.1336 8.85153C33.9622 9.05023 33.7219 9.18879 33.4528 9.24407V9.30032C33.7718 9.31334 34.0721 9.44207 34.2883 9.65838C34.5044 9.87468 34.6187 10.161 34.6062 10.4546C34.5826 10.6657 34.5135 10.8704 34.4028 11.0566C34.2922 11.2428 34.1423 11.4068 33.9619 11.5389C33.7815 11.6711 33.5743 11.7688 33.3523 11.8263C33.1304 11.8838 32.8982 11.8999 32.6694 11.8738C32.4401 11.9025 32.2069 11.8885 31.9837 11.8325C31.7604 11.7766 31.5516 11.6798 31.3699 11.548C31.1881 11.4162 31.037 11.252 30.9256 11.0653C30.8143 10.8786 30.7449 10.6732 30.7216 10.4613H31.4948C31.5415 10.9179 31.9972 11.2125 32.6694 11.2125C33.3416 11.2125 33.7804 10.8816 33.7804 10.4152C33.7804 9.94215 33.3671 9.65074 32.6448 9.65074H31.9293V9.00266Z" fill="white" />
    <path d="M35.4847 10.7393C35.4847 10.0909 36.01 9.71704 36.9402 9.66391L38.0003 9.60766V9.29673C38.0003 8.91625 37.7279 8.70141 37.1991 8.70141C36.768 8.70141 36.4701 8.84712 36.3835 9.10181H35.6358C35.7147 8.48305 36.3478 8.08618 37.2356 8.08618C38.2167 8.08618 38.7701 8.53578 38.7701 9.29673V11.7581H38.0258V11.2518H37.9647C37.8406 11.4334 37.6665 11.5814 37.46 11.6808C37.2534 11.7802 37.022 11.8273 36.7892 11.8174C36.6249 11.8331 36.4588 11.817 36.3017 11.77C36.1446 11.7231 36 11.6463 35.8771 11.5448C35.7542 11.4432 35.6558 11.319 35.5882 11.1803C35.5206 11.0416 35.4853 10.8913 35.4847 10.7393ZM38.0003 10.4315V10.1303L37.0446 10.1866C36.5057 10.2198 36.2612 10.3885 36.2612 10.7061C36.2612 11.0303 36.5668 11.219 36.9869 11.219C37.11 11.2305 37.2344 11.219 37.3526 11.1853C37.4708 11.1517 37.5804 11.0965 37.675 11.023C37.7695 10.9495 37.8471 10.8593 37.903 10.7577C37.9588 10.6561 37.992 10.5451 38.0003 10.4315Z" fill="white" />
    <path d="M40.8197 8.7542V11.7581H40.0474V8.15576H42.6241V8.75416L40.8197 8.7542Z" fill="white" />
    <path d="M47.305 9.95543C47.305 11.1 46.6761 11.8175 45.6882 11.8175C45.4457 11.8328 45.2035 11.7839 44.9901 11.6767C44.7768 11.5695 44.6011 11.4085 44.4838 11.2125H44.4227V12.9558H43.6504V8.15583H44.3939V8.75116H44.4516C44.571 8.54832 44.7489 8.37979 44.9658 8.26418C45.1826 8.14858 45.43 8.0904 45.6806 8.09608C46.6761 8.09606 47.305 8.81051 47.305 9.95543ZM46.5106 9.95543C46.5106 9.1945 46.1193 8.73473 45.465 8.73473C44.8149 8.73473 44.4083 9.20426 44.4083 9.95856C44.4083 10.7093 44.8149 11.1793 45.4616 11.1793C46.1193 11.1793 46.5106 10.7195 46.5106 9.95543Z" fill="white" />
    <path d="M48.3337 13.0483V12.4362C48.4172 12.446 48.5014 12.4504 48.5857 12.4495C48.7502 12.4607 48.9135 12.4156 49.0441 12.3228C49.1748 12.2301 49.2636 12.0961 49.2935 11.9468L49.3512 11.7714L47.9347 8.15576H48.7758L49.757 11.0171H49.8181L50.7958 8.15576H51.6148L50.1559 11.9069C49.8215 12.7835 49.4514 13.0648 48.6536 13.0648C48.5891 13.0647 48.4058 13.0581 48.3337 13.0483Z" fill="white" />
    <path d="M52.2166 10.7359H52.9855C53.0611 11.0535 53.3845 11.2191 53.8368 11.2191C54.3511 11.2191 54.6669 11.0105 54.6669 10.6863C54.6669 10.339 54.3834 10.1734 53.8368 10.1734H53.2512V9.62104H53.8266C54.2934 9.62104 54.5311 9.45581 54.5311 9.15464C54.5311 8.89019 54.3011 8.68824 53.8266 8.68824C53.3946 8.68824 53.0789 8.85699 53.0288 9.14137H52.2845C52.3401 8.8146 52.5329 8.52099 52.8213 8.32368C53.1097 8.12637 53.4707 8.04113 53.8266 8.08629C54.7568 8.08629 55.289 8.4734 55.289 9.08199C55.2933 9.25147 55.2343 9.41718 55.1219 9.55142C55.0094 9.68566 54.8503 9.78029 54.6711 9.8195V9.87575C54.8844 9.90107 55.0802 9.9976 55.2213 10.147C55.3625 10.2964 55.4391 10.4883 55.4367 10.6863C55.4367 11.3711 54.8035 11.8242 53.8368 11.8242C52.9821 11.8242 52.2879 11.4636 52.2166 10.7359Z" fill="white" />
    <path d="M56.5488 11.7581V8.15576H57.3144V10.6335H57.3755L59.179 8.15577H59.9446V11.7581H59.179V9.2671H59.1213L57.3144 11.7581L56.5488 11.7581Z" fill="white" />
    <path d="M64.1621 8.75416H62.9297V11.7581H62.1565V8.75416H60.9241V8.15576H64.1621V8.75416Z" fill="white" />
    <path d="M68.3224 10.7855C68.2172 11.1147 67.9896 11.3994 67.6795 11.5899C67.3694 11.7804 66.9965 11.8645 66.6258 11.8277C66.368 11.8339 66.1118 11.7885 65.8749 11.6944C65.6381 11.6004 65.4263 11.4601 65.2543 11.2832C65.0823 11.1063 64.9542 10.897 64.8789 10.6699C64.8035 10.4429 64.7828 10.2035 64.818 9.96827C64.7837 9.73237 64.8048 9.49242 64.88 9.26468C64.9552 9.03694 65.0826 8.82673 65.2537 8.64827C65.4248 8.46981 65.6355 8.32728 65.8717 8.23031C66.1078 8.13334 66.3639 8.08421 66.6224 8.08624C67.7113 8.08624 68.3691 8.77104 68.3691 9.90224V10.1503H65.6056V10.1902C65.5934 10.3224 65.6114 10.4555 65.6584 10.581C65.7053 10.7064 65.7803 10.8214 65.8785 10.9186C65.9766 11.0158 66.0958 11.093 66.2283 11.1453C66.3608 11.1976 66.5037 11.2238 66.6479 11.2222C66.8325 11.2425 67.0195 11.2118 67.1852 11.134C67.3508 11.0563 67.4876 10.9349 67.5781 10.7854L68.3224 10.7855ZM65.6056 9.62453H67.5815C67.5912 9.50369 67.5734 9.38231 67.5292 9.26817C67.485 9.15404 67.4153 9.04969 67.3247 8.96181C67.234 8.87393 67.1244 8.80447 67.0029 8.75789C66.8814 8.71131 66.7506 8.68864 66.619 8.69133C66.4854 8.68966 66.3528 8.71265 66.229 8.75896C66.1052 8.80528 65.9927 8.87396 65.8982 8.96095C65.8038 9.04795 65.7292 9.1515 65.679 9.26547C65.6287 9.37945 65.6038 9.50154 65.6056 9.62453H65.6056Z" fill="white" />
    <path d="M71.5968 8.15576H73.3613C74.1269 8.15576 74.5758 8.51279 74.5758 9.07176C74.577 9.24913 74.512 9.42149 74.3916 9.56001C74.2713 9.69852 74.1029 9.79479 73.9147 9.8327V9.88895C74.1432 9.90224 74.3575 9.99582 74.5132 10.1504C74.669 10.3049 74.7543 10.5086 74.7515 10.7194C74.7515 11.3479 74.238 11.7581 73.3791 11.7581H71.5968V8.15576ZM72.3624 8.70147V9.63426H73.1279C73.5947 9.63426 73.8179 9.46903 73.8179 9.16435C73.8179 8.88349 73.6236 8.70146 73.2136 8.70146L72.3624 8.70147ZM72.3624 10.1569V11.2124H73.2782C73.7348 11.2124 73.9792 11.0269 73.9792 10.6862C73.9792 10.3257 73.7025 10.1569 73.1814 10.1569L72.3624 10.1569Z" fill="white" />
  </svg>
);