import React, { FC } from "react";
import { PaymentSystem } from "../PaymentSystem";

import './SystemsBar.scss';

export const SystemsBar: FC = () => (
  <div className="Systems-bar__container">
    <PaymentSystem type='mc' />
    <PaymentSystem type='mir' />
    <PaymentSystem type='up' />
    <PaymentSystem type='visa' />
  </div>
);