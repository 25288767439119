import React, { FC } from "react";
import InputMask from 'react-input-mask';

import './Input.scss';

interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

export const Input: FC<Props> = ({ onChange, value }) => {
  return (
    <div className="Input">
      <p className="Input__text">+7</p>
      <InputMask onChange={onChange} value={value} className="Input__input" mask="(999) 999-99-99" placeholder="(123) 456-78-90" />
    </div>
  );
};