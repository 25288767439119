import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { TieIcon } from '../../common';

import './Header.scss';

export const Header: FC = () => (
  <header className='Header__container'>
    <Link to='/'>
      <TieIcon />
    </Link>

    <p className="Header__text">
      Онлайн-агенство <br />
      знакомств
    </p>
  </header>
);