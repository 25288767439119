import React, { FC } from "react";

export const AloneIcon: FC = () => (
  <svg viewBox="0 0 163 113" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1623_5355)">
      <path opacity="0.3" d="M116.544 77.2671C116.544 87.4934 110.471 91.064 102.979 91.064C95.4874 91.064 89.4141 87.4934 89.4141 77.2671C89.4141 67.0407 102.979 54.0312 102.979 54.0312C102.979 54.0312 116.544 67.0407 116.544 77.2671Z" fill="#F6193D" />
      <path d="M102.624 80.9412L108.406 70.3531L102.646 79.5986L102.708 75.7505L106.693 68.09L102.725 74.7319L102.837 67.8107L107.104 61.7122L102.855 66.7224L102.925 54.0312L102.503 70.1124L98.182 63.4918L102.451 71.4695L102.046 79.1992L102.035 78.9941L97.0332 71.999L102.019 79.7189L101.969 80.6856L101.96 80.7002L101.964 80.7795L100.938 91.0945H102.308L102.473 90.2612L107.447 82.5602L102.485 89.4997L102.624 80.9412Z" fill="#3F3D56" />
      <path d="M163 44.3862C163 63.921 151.398 70.7418 137.087 70.7418C122.775 70.7418 111.174 63.921 111.174 44.3862C111.174 24.8514 137.087 0 137.087 0C137.087 0 163 24.8514 163 44.3862Z" fill="#F2F2F2" />
      <path d="M136.143 67.7536L136.409 51.4047L147.453 31.1788L136.45 48.84L136.57 41.4889L144.182 26.8557L136.601 39.5435V39.5437L136.816 26.3223L144.967 14.6724L136.849 24.2433L136.984 0L136.141 32.0937L136.21 30.7697L127.923 18.0721L136.077 33.3112L135.305 48.0771L135.282 47.6853L125.729 34.3229L135.253 49.0698L135.157 50.9165L135.139 50.9443L135.147 51.0958L133.188 88.5582H135.806L136.12 69.2082L145.621 54.4974L136.143 67.7536Z" fill="#3F3D56" />
      <path d="M161.936 85.2873C161.936 91.1179 124.151 113 79.9794 113C35.808 113 0 101.675 0 95.8445C0 90.0139 34.9291 96.9442 79.1005 96.9442C123.272 96.9442 161.936 79.4567 161.936 85.2873Z" fill="#3F3D56" />
      <path opacity="0.1" d="M161.936 85.2873C161.936 91.1179 124.151 113 79.9794 113C35.808 113 0 101.675 0 95.8445C0 90.0139 34.9291 96.9442 79.1005 96.9442C123.272 96.9442 161.936 79.4567 161.936 85.2873Z" fill="black" />
      <path d="M161.936 85.2873C161.936 91.1179 124.151 106.402 79.9794 106.402C35.808 106.402 0 101.675 0 95.8445C0 90.0139 34.9291 90.3459 79.1005 90.3459C123.272 90.3459 161.936 79.4567 161.936 85.2873Z" fill="#3F3D56" />
      <path opacity="0.1" d="M80.8746 99.582C99.6321 99.582 114.838 98.4047 114.838 96.9524C114.838 95.5001 99.6321 94.3228 80.8746 94.3228C62.1171 94.3228 46.9111 95.5001 46.9111 96.9524C46.9111 98.4047 62.1171 99.582 80.8746 99.582Z" fill="black" />
      <path d="M53.9687 44.2583C53.9687 44.2583 63.9117 44.4426 62.0704 46.1936C60.2291 47.9446 53.3242 46.1936 53.3242 46.1936L53.9687 44.2583Z" fill="#FBBEBE" />
      <path d="M33.252 22.9904C33.252 22.9904 37.2665 22.2727 38.6624 25.5168C40.0582 28.7608 42.9249 38.0126 42.9249 38.0126C42.9249 38.0126 50.5966 43.0957 51.992 42.9287C53.3874 42.7617 55.7728 43.1025 55.3353 44.6263C54.8977 46.1502 55.0919 47.8781 54.0846 48.0898C53.0773 48.3016 51.4751 46.2801 50.249 47.2538C49.0229 48.2275 38.0954 44.671 37.3939 43.9342C36.6924 43.1973 30.8623 27.2408 30.8623 27.2408C30.8623 27.2408 31.0955 22.9384 33.252 22.9904Z" fill="#8A87C8" />
      <path d="M41.8621 91.4883L42.3224 95.3588L38.4557 96.3727L36.2461 95.3588V91.949L41.8621 91.4883Z" fill="#FBBEBE" />
      <path d="M31.4588 93.9766L31.9191 97.8471L28.0524 98.8609L25.8428 97.8471V94.4373L31.4588 93.9766Z" fill="#FBBEBE" />
      <path d="M42.2303 49.1885L43.9795 55.5474L46.5573 68.4493C46.5573 68.4493 47.2939 71.5827 45.8208 75.3611C44.3478 79.1395 41.6779 91.4886 42.2303 92.2258C42.7827 92.9631 35.9698 93.516 36.0619 92.7788C36.1539 92.0415 37.0746 81.9042 37.0746 81.9042C37.0746 81.9042 38.3635 72.965 39.008 71.8591C39.6524 70.7533 36.0619 67.9885 36.0619 67.9885C36.0619 67.9885 35.1412 78.5866 33.7603 79.5082C32.3793 80.4297 32.5634 94.7141 31.9189 94.8984C31.2745 95.0827 26.5791 95.8199 26.0268 94.8984C25.4744 93.9768 26.0268 78.5866 26.0268 78.5866C26.0268 78.5866 29.157 66.8827 28.5125 65.2238C27.8681 63.565 28.2363 56.4689 28.2363 56.4689C28.2363 56.4689 25.8426 51.0317 27.1315 48.1748L42.2303 49.1885Z" fill="#2F2E41" />
      <path d="M41.9543 13.8001C41.9543 13.8001 38.0875 18.5923 38.2716 20.7119C38.4558 22.8315 32.1953 16.8413 32.1953 16.8413C32.1953 16.8413 36.7065 10.759 36.7065 9.46875C36.7065 8.17856 41.9543 13.8001 41.9543 13.8001Z" fill="#FBBEBE" />
      <path d="M41.9074 15.5973C45.2632 15.5973 47.9837 12.8742 47.9837 9.51498C47.9837 6.15578 45.2632 3.43262 41.9074 3.43262C38.5515 3.43262 35.8311 6.15578 35.8311 9.51498C35.8311 12.8742 38.5515 15.5973 41.9074 15.5973Z" fill="#FBBEBE" />
      <path d="M26.5945 45.741C26.3192 46.7676 25.9776 47.9315 26.0264 48.9121C26.0577 49.5471 27.7416 49.9636 30.0699 50.2364C32.2289 50.4898 34.9421 50.6188 37.4085 50.6852C39.9983 50.7552 42.3137 50.7552 43.4268 50.7552C46.7412 50.7552 44.0713 48.6356 42.7824 47.6219C41.4934 46.6082 41.7696 32.2317 41.8617 30.3885C41.9538 28.5454 41.0331 23.2003 41.0331 22.1866C41.0331 21.1728 38.8668 18.527 38.8668 18.527C38.8668 18.527 38.3632 19.9748 36.3378 18.0395C34.3123 16.1042 31.6424 15.5513 31.6424 15.5513C29.617 16.2885 26.2106 24.3983 26.0264 25.7807C25.9519 26.3419 26.0135 29.2163 26.1388 32.498C26.3201 37.2948 26.6368 42.9652 26.855 43.567C27.0272 44.0416 26.8366 44.8378 26.5945 45.741Z" fill="#8A87C8" />
      <path d="M36.4297 51.1235L37.0741 53.4275L34.5883 56.0079L33.7598 52.4137L36.4297 51.1235Z" fill="#FBBEBE" />
      <path d="M37.2584 94.3453C37.2584 94.3453 39.1917 96.5571 40.6648 95.0826C42.1378 93.6081 42.0458 92.6865 42.322 92.6865C42.5981 92.6865 46.7411 96.3728 46.7411 96.3728C46.7411 96.3728 53.8301 98.0316 49.9634 99.2297C46.0966 100.428 35.1409 99.5983 35.1409 99.2297C35.1409 98.861 34.8647 93.9767 35.8774 93.9767L37.2584 94.3453Z" fill="#2F2E41" />
      <path d="M26.855 96.8336C26.855 96.8336 28.7884 99.0454 30.2615 97.5709C31.7345 96.0964 31.6424 95.1748 31.9186 95.1748C32.1948 95.1748 36.3378 98.8611 36.3378 98.8611C36.3378 98.8611 43.4268 100.52 39.5601 101.718C35.6933 102.916 24.7375 102.087 24.7375 101.718C24.7375 101.349 24.4613 96.465 25.4741 96.465L26.855 96.8336Z" fill="#2F2E41" />
      <path d="M35.0857 14.3702C34.8894 14.4972 34.6339 14.6258 34.4373 14.4992C33.7066 12.675 33.1604 10.7821 32.807 8.84876C32.6829 8.16968 32.5967 7.41476 32.9736 6.83661C33.1222 6.60859 33.3362 6.42212 33.4451 6.17261C33.61 5.79475 33.5015 5.35738 33.5562 4.94871C33.671 4.09254 34.4795 3.49539 35.2926 3.20578C36.1056 2.91617 36.9886 2.82633 37.7636 2.4463C38.4995 2.08546 39.0889 1.48701 39.7712 1.03288C40.4536 0.578752 41.3276 0.268247 42.0876 0.575117C42.7598 0.846588 43.1786 1.53249 43.814 1.88173C44.2452 2.11873 44.7455 2.18468 45.2268 2.28641C46.5646 2.57051 47.8205 3.1546 48.9002 3.99472C49.1629 4.18229 49.3894 4.41602 49.5687 4.68456C50.3841 6.02836 48.9242 7.85532 49.5683 9.28931L48.1758 8.18945C47.8039 7.86386 47.3846 7.59707 46.9322 7.3983C46.4709 7.22342 45.9247 7.2023 45.5057 7.4628C44.9177 7.82839 44.7583 8.59216 44.5528 9.25383C44.3474 9.91549 43.8933 10.6492 43.2013 10.6323C42.2602 10.6092 41.9311 9.2946 41.0866 8.87839C40.5361 8.60707 39.8431 8.78574 39.3737 9.18146C38.9044 9.57714 38.6228 10.1513 38.4163 10.7297C38.2872 11.0911 38.1661 11.4985 37.9368 11.8118C37.684 12.1574 37.3606 12.1852 37.0905 12.4432C36.3875 13.1149 35.9553 13.8079 35.0857 14.3702Z" fill="#2F2E41" />
      <path opacity="0.1" d="M26.5944 45.7407C27.7388 47.4023 29.0286 49.1164 30.0699 50.2361C32.2288 50.4895 34.942 50.6186 37.4084 50.6849C36.9678 50.0869 36.4168 49.5789 35.7853 49.1883C34.5885 48.451 31.7344 39.6961 31.7344 39.6961C31.7344 39.6961 35.2329 30.6647 36.1536 27.2549C37.0742 23.8451 33.4837 21.9098 33.4837 21.9098C31.8265 20.5275 28.9724 23.753 28.9724 23.753C28.9724 23.753 27.516 28.1507 26.1387 32.4977C26.32 37.2945 26.6367 42.9649 26.8549 43.5667C27.0271 44.0413 26.8365 44.8376 26.5944 45.7407Z" fill="black" />
      <path d="M33.2074 21.1725C33.2074 21.1725 36.7979 23.1078 35.8773 26.5176C34.9566 29.9274 31.4581 38.9588 31.4581 38.9588C31.4581 38.9588 34.3122 47.7137 35.509 48.451C36.7059 49.1882 38.363 50.9392 37.0741 51.8608C35.7852 52.7824 34.8646 54.2569 33.9439 53.7961C33.0232 53.3353 33.0232 50.7549 31.4581 50.7549C29.893 50.7549 23.5405 41.1706 23.4484 40.1568C23.3564 39.1431 28.6962 23.0157 28.6962 23.0157C28.6962 23.0157 31.5502 19.7902 33.2074 21.1725Z" fill="#8A87C8" />
      <path d="M107.869 46.3621L106.833 45.3253C106.229 44.7206 105.41 44.3809 104.556 44.3809C103.701 44.3809 102.882 44.7206 102.278 45.3253L82.188 65.4349L62.0984 45.3253C61.4943 44.7206 60.6749 44.3809 59.8206 44.3809C58.9662 44.3809 58.1468 44.7206 57.5427 45.3253L56.507 46.3621C56.2079 46.6615 55.9706 47.017 55.8087 47.4082C55.6468 47.7994 55.5635 48.2187 55.5635 48.6422C55.5635 49.0656 55.6468 49.4849 55.8087 49.8761C55.9706 50.2674 56.2079 50.6228 56.507 50.9223L76.5966 71.0318L56.507 91.1414C56.2079 91.4409 55.9706 91.7963 55.8087 92.1875C55.6468 92.5788 55.5635 92.9981 55.5635 93.4215C55.5635 93.845 55.6468 94.2643 55.8087 94.6555C55.9706 95.0467 56.2079 95.4022 56.507 95.7016L57.5427 96.7384C58.1468 97.3431 58.9662 97.6828 59.8206 97.6828C60.6749 97.6828 61.4943 97.3431 62.0984 96.7384L82.188 76.6288L102.278 96.7384C102.882 97.3431 103.701 97.6828 104.556 97.6828C105.41 97.6828 106.229 97.3431 106.833 96.7384L107.869 95.7016C108.473 95.0969 108.813 94.2767 108.813 93.4215C108.813 92.5663 108.473 91.7461 107.869 91.1414L87.7794 71.0318L107.869 50.9223C108.473 50.3175 108.813 49.4974 108.813 48.6422C108.813 47.787 108.473 46.9668 107.869 46.3621Z" fill="#F6193D" />
      <path opacity="0.1" d="M55.79 92.2344L75.2831 72.722L75.0955 72.5342L56.507 91.1411C56.1956 91.4525 55.9516 91.8246 55.79 92.2344Z" fill="black" />
      <path opacity="0.1" d="M56.2292 47.0158C56.8333 46.4111 57.6527 46.0713 58.507 46.0713C59.3614 46.0713 60.1808 46.4111 60.7849 47.0158L80.8745 67.1254L100.964 47.0158C101.568 46.4111 102.388 46.0713 103.242 46.0713C104.096 46.0713 104.916 46.4111 105.52 47.0158L106.556 48.0525C107 48.4974 107.304 49.0627 107.432 49.6787C107.559 50.2947 107.504 50.9345 107.272 51.5194L107.869 50.9223C108.168 50.6228 108.405 50.2674 108.567 49.8761C108.729 49.4849 108.813 49.0656 108.813 48.6422C108.813 48.2187 108.729 47.7994 108.567 47.4082C108.405 47.017 108.168 46.6615 107.869 46.3621L106.833 45.3253C106.229 44.7206 105.41 44.3809 104.555 44.3809C103.701 44.3809 102.882 44.7206 102.278 45.3253L82.188 65.4349L62.0984 45.3253C61.4943 44.7206 60.6749 44.3809 59.8205 44.3809C58.9662 44.3809 58.1468 44.7206 57.5427 45.3253L56.507 46.3621C56.1956 46.6734 55.9516 47.0456 55.79 47.4554L56.2292 47.0158Z" fill="black" />
      <path opacity="0.1" d="M87.967 71.2197L86.4658 72.7224L106.555 92.8319C107 93.2768 107.304 93.8421 107.432 94.4581C107.559 95.0741 107.504 95.714 107.272 96.2988L107.869 95.7017C108.168 95.4022 108.405 95.0468 108.567 94.6555C108.729 94.2643 108.812 93.845 108.812 93.4216C108.812 92.9981 108.729 92.5788 108.567 92.1876C108.405 91.7964 108.168 91.4409 107.869 91.1415L87.967 71.2197Z" fill="black" />
      <path opacity="0.2" d="M23.3922 84.6278C23.3922 93.1211 18.3481 96.0867 12.1258 96.0867C5.90352 96.0867 0.859375 93.1211 0.859375 84.6278C0.859375 76.1345 12.1258 65.3296 12.1258 65.3296C12.1258 65.3296 23.3922 76.1345 23.3922 84.6278Z" fill="#F6193D" />
      <path d="M11.831 87.6794L16.6331 78.8855L11.8491 86.5643L11.901 83.3681L15.2105 77.0059L11.9147 82.5223L12.008 76.774L15.5519 71.7088L12.0226 75.8701L12.0809 65.3296L11.7303 78.6856L8.14161 73.187L11.6869 79.8126L11.3512 86.2326L11.3412 86.0622L7.1875 80.2525L11.3286 86.6642L11.2866 87.4671L11.2791 87.4792L11.2825 87.545L10.4308 96.112H11.5688L11.7054 95.42L15.8364 89.024L11.7157 94.7874L11.831 87.6794Z" fill="#3F3D56" />
    </g>
    <defs>
      <clipPath id="clip0_1623_5355">
        <rect width="163" height="113" fill="white" />
      </clipPath>
    </defs>
  </svg>

);
