import { useStore } from "effector-react";
import React, { ChangeEvent, FC, Fragment, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { phoneStore, setPhone } from "../../common";
import { Button, Input, ProgressBar, Footer } from "../../components";

export const PhonePage: FC = () => {
  const phone = useStore(phoneStore);
  const [phoneNumber, setPhoneNumber] = useState(phone);
  const navigator  = useNavigate();

  const isBtnDisabled = useMemo(() => phoneNumber.replace(/[^0-9]/g, "").length !== 10, [phoneNumber]);

  const handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void = ({ target: { value } }) => setPhoneNumber(value);

  const handleButtonClick: ((event: React.MouseEvent) => void) = async e => {
    e.preventDefault();

    setPhone(phoneNumber);

    navigator('/rates');
  };

  return (
    <Fragment>
        <h1 className="Right-side__title">Оплатить тариф</h1>

        <ProgressBar />

        <h2 className="Right-side__sub-title">Номер телефона</h2>

        <p className="Right-side__info text text_semi-gray">
          Введи пожалуйста свой номер<br className="d-none_sl" />
          телефона, <br className="d-none d-block_sl" /> чтобы мы связали <br className="d-none_sl" />
          оплату с твоим профилем.
        </p>

        <div className="Right-side__controls">
          <Input onChange={handleInputChange} value={phoneNumber} />

          <Button className="Right-side__send-btn" onClick={handleButtonClick} text="Отправить" disabled={isBtnDisabled} />
        </div>
    </Fragment>
  );
}