import axios from 'axios';
import { createStore, createEvent, createEffect } from 'effector';
import { RateB } from '../models';

interface Profile {
  first_name: string;
  age: number;
}
interface RateListResponse {
  data: {
    items: RateB[];
    profile: Profile;
  }
}

export const selectRate = createEvent<RateB>('select rate');

export const setPhone = createEvent<string>('set phone');

export const setNoUser = createEvent<boolean>('set no user');

export const setProfile = createEvent<Profile>('set profile');

export const getRates = createEffect(async (phone: string) => {
  setNoUser(false);
  try {
    const { data: { data: { items, profile } } } = await axios.get<RateListResponse>(`https://api.drsvat.com/api/v1/meeting-tariffs-remote-list?phone=${phone}`);

    setProfile(profile);

    return items;
  } catch (error) {
    console.warn('error', error);
    setNoUser(true);
    setPhone('');
  }

  return [];
});

export const rateStore = createStore<RateB>({} as RateB)
  .on(selectRate, (_, rate) => rate);

export const ratesStore = createStore<RateB[]>([])
  .on(getRates.done, (_, { result }) => result);

export const rateIDStore = createStore<number>(0);

export const phoneStore = createStore<string>(localStorage.getItem('phone') || '')
  .on(setPhone, (_, phone) => {
    localStorage.setItem('phone', phone);
    return phone;
  });

export const noUserStore = createStore<boolean>(false)
  .on(setNoUser, (_, value) => value);

export const profileStore = createStore<Profile>(JSON.parse(localStorage.getItem('profile') || '{}'))
  .on(setProfile, (_, profile) => {
    localStorage.setItem('profile', JSON.stringify(profile));
    return profile;
  });
