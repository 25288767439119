import React, { FC } from 'react';
import {
  BrowserRouter as Router
} from 'react-router-dom';
import './App.scss';
import { LeftSide, TieLine } from './components';
import { RightSide } from './components/RightSide';

const App: FC = () => (
  <div className="App__container">
    <div className="App__content">
      <Router>
        <LeftSide />
        <RightSide />
        <TieLine />
      </Router>
    </div>
  </div>
);

export default App;
