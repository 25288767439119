import React, { FC, Fragment, useEffect } from "react";
import { AloneIcon, AppStoreIcon, setNoUser } from "../../common";
import { BackButton } from "../../components";

import './NoUser.scss'

export const NoUserPage: FC = () => {
  useEffect(() => {
    setNoUser(false);
    window.scrollTo({ top: window.innerHeight });
  }, []);

  return (
    <Fragment>
      <h1 className="Failure-page__title Right-side__title">Вас нет в нашей базе</h1>

      <BackButton path="/" />

      <p className="Right-side__info text text_semi-gray">
        Пройдите по ссылке и зарегистрирутейсь в гашем приложении для дальнейшей оплаты
      </p>

      <div className="No-user-page__controls-container Failure-page__controls-container">
        <a href='https://play.google.com/store/apps/details?id=com.drsvat.drSvatApp&hl=en_US&gl=US' className="Right-side__google-play" target="_blank" rel="noreferrer"></a>
        <a href='https://apps.apple.com/ru/app/dr-svat-%D1%81%D0%B5%D1%80%D1%8C%D0%B5%D0%B7%D0%BD%D1%8B%D0%B5-%D0%B7%D0%BD%D0%B0%D0%BA%D0%BE%D0%BC%D1%81%D1%82%D0%B2%D0%B0/id1545816827' target="_blank" rel="noreferrer">
          <AppStoreIcon />
        </a>
      </div>

      <div className="Failure-page__icon">
        <AloneIcon />
      </div>

    </Fragment>
  )
}