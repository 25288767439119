import React, { FC } from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { find, propEq } from 'ramda';

import { RateB } from '../../../common/models';
import { profileStore, selectRate, SingleIcon } from '../../../common';
import { useStore } from 'effector-react';

interface Props {
  rate: RateB;
}

export const RateBox: FC<Props> = ({ rate }) => {
  const navigator = useNavigate();
  const { age } = useStore(profileStore);

  const { name, offers_count, body, price_for_one, price, sale, category, id } = rate;

  const handleRateClick = (id: number) => () => {
    selectRate(rate);
    (window as any).dataLayer.push({
      "ecommerce": {
        "currencyCode": "RUB",
        "detail": {
          "products": [
            {
              id,
              name,
              price,
              category: age,
              "quantity": offers_count,
              "variant": body.split(' ')[0]
            }
          ]
        }
      }
    });
    navigator('/checkout');
  };

  let title;

  switch (category) {
    case 'benefit':
      title = 'Выгодно';
      break;

    case 'hit':
      title = 'Хит';
      break;

    default:
      title = '';
  }

  const classNames = cn('Rates-page__rate', { 'Rates-page__rate_active': category === 'hit' });

  return (
    <div className={classNames} onClick={handleRateClick(id)}>
      {category && (
        <div className="Rates-page__tip">
          <p>{title}</p>
        </div>
      )}

      <p className="Rates-page__name">
        {name}
      </p>

      <div className="Rates-page__line">
        <p className="Rates-page__text"><strong>{offers_count}</strong> анкет</p>
        {!!sale && <p className="Rates-page__fair">Выгода:</p>}
      </div>

      <div className="Rates-page__line">
        <p className="Rates-page__text"><strong>{body.split(' ')[0]}</strong> свидани{body.split(' ')[0] === '1' ? 'е' : 'й'}</p>
        {!!sale && <p className="Rates-page__discount">{sale}%</p>}
        {!sale && (
          <div className="Rates-page__icon">
            <SingleIcon />
          </div>
        )}
      </div>

      <div className="Rates-page__line">
        <p className="Rates-page__price-per-date">
          <span>{price_for_one} ₽</span> за свидание
        </p>
        {category && (
          <p className="Rates-page__price">
            {price.split('.')[0]} ₽
          </p>
        )}
      </div>
    </div>

  );
}