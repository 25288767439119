import React, { FC } from "react";

export const TieBoxIcon: FC = () => (
  <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_1623_6167" style={{ "maskType": 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="120" height="120">
      <path fillRule="evenodd" clipRule="evenodd" d="M27.3333 0C12.2375 0 0 12.2375 0 27.3333V92.6667C0 107.762 12.2375 120 27.3333 120H92.6667C107.762 120 120 107.762 120 92.6667V27.3333C120 12.2375 107.762 0 92.6667 0H27.3333Z" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0_1623_6167)">
      <circle cx="59.9999" cy="59.9998" r="51.8182" fill="black" fillOpacity="0.09" />
      <circle cx="59.9999" cy="59.9998" r="51.5682" stroke="#2869BF" strokeOpacity="0.04" strokeWidth="0.5" />
      <ellipse cx="60.0005" cy="59.9997" rx="32.7273" ry="32.7273" fill="black" fillOpacity="0.09" />
      <path d="M92.4777 59.9997C92.4777 77.9364 77.9372 92.477 60.0005 92.477C42.0638 92.477 27.5232 77.9364 27.5232 59.9997C27.5232 42.063 42.0638 27.5225 60.0005 27.5225C77.9372 27.5225 92.4777 42.063 92.4777 59.9997Z" stroke="#2869BF" strokeOpacity="0.04" strokeWidth="0.5" />
      <path fillRule="evenodd" clipRule="evenodd" d="M60.6385 0H60.213V60.213H0V60.6385H60.213V120H60.6385V60.6385H120V60.213H60.6385V0Z" fill="#6277A1" fillOpacity="0.1" />
      <rect width="120" height="120" transform="matrix(-1 0 0 1 120 0)" fill="#F6193D" />
      <path d="M48.0722 46.582L60.0003 53.3109L71.9284 46.582L95.7034 33.2034V60.0398V86.7971L71.9284 73.4185L60.0003 66.6896L48.0722 73.4185L24.2972 86.7971V60.0398V33.2034L48.0722 46.582Z" fill="white" />
    </g>
  </svg>
);
