import React, { FC, Fragment, useEffect } from 'react';
import { useStore } from 'effector-react';
import { useNavigate } from 'react-router-dom';

import { BackButton, ProgressBar } from '../../components';
import { getRates, noUserStore, phoneStore, ratesStore, profileStore } from '../../common';
import { RateBox } from './Subs';

import './Rates.scss';

export const RatesPage: FC = () => {
  const phone = useStore(phoneStore);
  const rates = useStore(ratesStore);
  const noUser = useStore(noUserStore);
  const profile = useStore(profileStore);

  const navigator  = useNavigate();

  useEffect(() => {
    if (noUser) {
      navigator('/no-user');
      return;
    }

    if (phone === '') {
      navigator('/');
    } else {
      getRates(`+7 ${phone}`);
    }
  }, [phone, noUser]);

  return rates ? (
    <Fragment>
      <h1 className="Right-side__title">Оплатить тариф</h1>

      <ProgressBar step="two" />

      <BackButton path="/" />

      <h2 className="Right-side__sub-title Right-side__sub-title_nm">{profile.first_name}, привет</h2>

      <p className="Right-side__info text text_semi-gray">
        Выбери тариф и сделай<br className="d-none_sl" />
        возможно главную <br className="d-none d-block_sl" /> инвестицию<br className="d-none_sl" />
        в своей жизни :)
      </p>

      <div className="Rates-page__container">
        {
          rates.map((rate) => (
            <RateBox key={rate.name} rate={rate} />
          ))
        }
      </div>

    </Fragment>
  ) : null;
};
