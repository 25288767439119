import React, { FC } from 'react';

import { FacebookIcon, InstagramIcon, OKIcon, VKIcon, YouTubeIcon } from './Subs';

import './Social.scss';

type Socials = 'instagram' | 'facebook' | 'vk' | 'ok' | 'youtube';

interface Props {
  name: Socials;
}

const getIcon = (name: Socials): JSX.Element => {
  switch (name) {
    case 'facebook':

      return (
        <a href="https://www.facebook.com/vlad.medvedev" className="social" target="_blank" rel="noreferrer">
          <FacebookIcon />
        </a>
      );

    case 'instagram':

      return (
        <a href="https://www.instagram.com/vlad_dr.svat/" className="social" target="_blank" rel="noreferrer">
          <InstagramIcon />
        </a>
      );

    case 'ok':

      return (
        <a href="https://ok.ru/dr.svat" className="social" target="_blank" rel="noreferrer">
          <OKIcon />
        </a>
      );


    case 'vk':

      return (
        <a href="https://vk.com/va_medvedev" className="social" target="_blank" rel="noreferrer">
          <VKIcon />
        </a>
      );

    case 'youtube':

      return (
        <a href="https://www.youtube.com/channel/UCffXhC_J-OjEAn2pOAlN-kQ" className="social" target="_blank" rel="noreferrer">
          <YouTubeIcon />
        </a>
      );


    default:
      return (
        <a href="https://vk.com/va_medvedev" className="social" target="_blank" rel="noreferrer">
          <VKIcon />
        </a>
      );

  }
}

export const SocialIcon: FC<Props> = ({ name }) => (
  getIcon(name)
);
