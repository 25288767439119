import React, { FC } from "react";
import { Link } from "react-router-dom";
import { ArrowLeft } from "../../common";

import './BackButton.scss';

interface Props {
  path: string;
}

export const BackButton: FC<Props> = ({ path }) => {

  return (
    <Link className="Back-button" to={path}>
      <ArrowLeft /> Назад
    </Link>
  )
};