import React, { FC } from "react";
import { ArrowIcon, PairIcon, TieBoxIcon } from "../../common";
import { Button } from "../Button";
import { Header } from "../Header";
import { SystemsBar } from "../SystemsBar";

import './LeftSide.scss';

export const LeftSide: FC = () => {

  const handleButtonClick: ((event: React.MouseEvent) => void) = e => {
    e.preventDefault();
    window.scrollTo({ top: window.innerHeight, behavior: 'smooth' });
  };

  return (
    <div className="Left-side__container Left-side__container_top">
      <div className="Left-side__container-top" style={{ 'minHeight': window.innerHeight }}>
        <div>
          <Header />
          <div>
            <div className="Left-side__title-container">
              <TieBoxIcon />
              <div className="Left-side__title-sub-container">
                {/* <h1 className="Left-side__title-name">
                  Dr.Svat
                </h1> */}
                <h1 className="Left-side__title">
                  Осознанные <br />
                  знакомства
                </h1>
              </div>
            </div>

            <p className="Left-side__info text">
              В РФ сейчас есть ограничения с оплатами через GooglePay и ApplePay. <br /> <br />

              Мы придумали <span className="text_semi-bold">безопасное решение,</span> которое поможет оплатить выбранный тариф и встретить близкого человека.  <br /> <br />

              Совершить покупку можно здесь.
            </p>

            <Button className="Left-side__pay-btn" onClick={handleButtonClick} text="Перейти к оплате" onlyBorder icon={<ArrowIcon />} />

            <SystemsBar />
          </div>
        </div>

        <div className="Left-side__pair">
          <PairIcon />
        </div>
      </div>

      <div className="Left-side__footer">

        <a href="https://drsvat.com/terms" className="footer__line-item">
          Публичная оферта
        </a>

        <a href="https://drsvat.com/faq" className="footer__line-item">
          FAQ
        </a>

        <a href="https://drsvat.com/privacy" className="footer__line-item">
          Политика конфиденциальности
        </a>

        <a href="https://drsvat.com/company" className="footer__line-item">
          Об агентстве
        </a>

        <a href="https://drsvat.com/bonus" className="footer__line-item">
          Партнерская программа
        </a>

        <a href="https://drsvat.com/geography" className="footer__line-item">
          Города
        </a>


      </div>
    </div>
  )
};