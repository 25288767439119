import React, { FC } from 'react';

import { GrayTieIcon, SocialIcon } from '../../common';

import './Footer.scss';

export const Footer: FC = () => (
  <footer className="footer">
    <div className="footer__head">
      <div className="footer__head-line" />
      <GrayTieIcon />
      <div className="footer__head-line" />
    </div>

    <div className="footer__line-container">
      <div className="footer__line">
        <a href="https://drsvat.com/terms" className="footer__line-item text text_s">
          Публичная оферта
        </a>

        <a href="https://drsvat.com/faq" className="footer__line-item text text_s">
          FAQ
        </a>
      </div>

      <div className="footer__line">
        <a href="https://drsvat.com/privacy" className="footer__line-item text text_s">
          Политика конфиденциальности
        </a>

        <a href="https://drsvat.com/bonus" className="footer__line-item text text_s">
          Партнерская программа
        </a>
      </div>

      <div className="footer__line">
        <a href="https://drsvat.com/company" className="footer__line-item text text_s">
          Об агентстве
        </a>
        <a href="https://drsvat.com/geography" className="footer__line-item text text_s">
          Города
        </a>


      </div>

      <div className="footer__line">
        <a href="mailto:info@drsvat.ru" className="footer__line-item text text_s">
          info@drsvat.ru
        </a>
      </div>
    </div>

    <div className="footer__desktop-alignment-block">
      <div className="footer__line-container_desktop">
        <div className="footer__line">
          <a href="https://drsvat.com/terms" className="footer__line-item text text_s">
            Публичная оферта
          </a>

          <a href="https://drsvat.com/faq" className="footer__line-item text text_s">
            FAQ
          </a>

          <a href="https://drsvat.com/company" className="footer__line-item text text_s">
            Об агентстве
          </a>
        </div>
        <div className="footer__line-container_desktop">
          <div className="footer__line">
            <a href="https://drsvat.com/privacy" className="footer__line-item text text_s">
              Политика конфиденциальности
            </a>

            <a href="https://drsvat.com/bonus" className="footer__line-item text text_s">
              Партнерская программа
            </a>

            <a href="https://drsvat.com/geography" className="footer__line-item text text_s">
              Города
            </a>

          </div>
        </div>

        <div className="footer__line-container_desktop">
          <div className="footer__line" />
        </div>

        <div className="footer__line-container_desktop" />
      </div>


      <div className="footer__desktop-info">
        <a className="footer__email-text text text_s" href="mailto:info@drsvat.ru">info@drsvat.ru</a>
        <div className="footer__social">
          <SocialIcon name="facebook" />
          <SocialIcon name="vk" />
          <SocialIcon name="ok" />
          <SocialIcon name='instagram' />
        </div>
      </div>
    </div>

  </footer>
);
