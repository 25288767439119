import React, { FC, MouseEvent, ReactElement } from 'react';
import cn from 'classnames';

import './Button.scss';

interface Props {
  disabled?: boolean;
  text: string;
  onlyBorder?: boolean;
  icon?: ReactElement;
  className?: string;
  onClick: (event: React.MouseEvent) => void;
}

export const Button: FC<Props> = ({ text, disabled, onlyBorder, icon, className, onClick }) => {
  const classNames = cn('Button', className, { 'Button_disabled': disabled, 'Button_only-border': onlyBorder });

  return (
    <button onClick={ onClick } className={classNames} disabled={disabled}>
      <span>{text}</span> {icon && <span className='Button__icon'>{icon}</span>}
    </button>
  )
};
